import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from '../index'

type RoleState = {
  isAdmin: boolean
}

const initialState: RoleState = {
  isAdmin: false,
}

const roleSlice = createSlice({
  name: 'role',
  initialState: initialState,
  reducers: {
    setAdmin: (state, action: PayloadAction<boolean>) => {
      state.isAdmin = action.payload
    },
  },
})

export const roleReducer = roleSlice.reducer
export const roleActions = roleSlice.actions

const selectSlice = (state: RootState) => state.role

export const isAdminSelector = createSelector(selectSlice, (roleState) => roleState.isAdmin)
