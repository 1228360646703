import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { useStyles } from './styles'

export const Unauthorized: FC = () => {
  const { t } = useTranslation()
  const styles = useStyles()

  return (
    <>
      <Box>
        <Typography variant="h6" sx={styles.header}>
          {t('title')}
        </Typography>
      </Box>
      <Box sx={styles.content}>
        <Box sx={styles.title}>
          <Typography variant="h3" fontSize={48}>
            {t('unauthorized.403')}
          </Typography>
        </Box>
        <Box sx={styles.text}>
          <Typography variant="h3" fontSize={48}>
            {t('unauthorized.text')}
          </Typography>
        </Box>
      </Box>
    </>
  )
}
