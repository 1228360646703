import React, { FC } from 'react'
import AddIcon from '@mui/icons-material/Add'
import Button from '@mui/material/Button'

type Props = {
  title: string
  onClick: () => void
}
export const FloatingButton: FC<Props> = ({ title, onClick }) => {
  return (
    <Button
      onClick={onClick}
      startIcon={<AddIcon />}
      sx={{
        position: 'fixed',
        right: 48,
        bottom: 32,
        height: 40,
        borderRadius: 8,
        textTransform: 'none',
        background: (theme) => theme.palette.secondary.main,
        color: (theme) => theme.palette.secondary.contrastText,
      }}
      variant="contained"
    >
      {title}
    </Button>
  )
}
