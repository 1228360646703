import React from 'react'
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form'
import { TextFieldVariants } from '@mui/material/TextField'
import { DatePicker } from '@mui/x-date-pickers'

type Props<Values extends FieldValues> = {
  name: FieldPath<Values>
  control: Control<Values>
  variant?: TextFieldVariants
  disabled?: boolean
}

export const DatePickerController = <Values extends FieldValues>({
  name,
  control,
  variant = 'filled',
  disabled,
}: Props<Values>) => {
  return (
    <Controller
      name={name}
      control={control}
      disabled={disabled}
      render={({ field: { onChange, value, ref }, fieldState }) => (
        <DatePicker
          value={value}
          onChange={onChange}
          timezone="UTC"
          slotProps={{
            textField: {
              inputRef: ref,
              size: 'small',
              variant: variant,
              fullWidth: true,
              disabled: disabled,
              sx: {
                '& .MuiInputBase-input': {
                  paddingTop: '9px',
                  paddingBottom: '8px',
                },
              },
              error: !!fieldState.error?.message,
              helperText: fieldState.error?.message,
            },
          }}
          sx={{ width: '100%' }}
        />
      )}
    />
  )
}
