import { useMemo } from 'react'
import * as Yup from 'yup'

import { prepareCustomMessages } from '../../../yup'

prepareCustomMessages()

export const useSchema = () => {
  return useMemo(
    () =>
      Yup.object().shape({
        type: Yup.string().required(),
        name: Yup.string().required().max(255),
        phoneNumber: Yup.string()
          .test('empty-or-matches', 'Text není platný podle regulárního výrazu', function (value) {
            return (
              value === undefined ||
              value === null ||
              value === '' ||
              /^(\+\d{1,4}\s?)?(\d{3}\s?\d{3}\s?\d{3})$/.test(value)
            )
          })
          .max(255),
        email: Yup.string().optional().email().max(255),
        note: Yup.string().optional().max(255),
        pickupPointId: Yup.number().required(),
        pickupDate: Yup.date().required(),
        products: Yup.array()
          .required()
          .of(
            Yup.object().shape({
              productId: Yup.number().required(),
              count: Yup.number().required(),
            })
          ),
      }),
    []
  )
}
