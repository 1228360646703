import { Component, ComponentType, ReactNode } from 'react'
import { useLocation } from 'react-router-dom'

import { ErrorPage } from '../../components/ErrorPage'

type Props = {
  children: ReactNode
  location: ReturnType<typeof useLocation>
}

type State = {
  hasError: boolean
}

type WithRouterProps = {
  location: ReturnType<typeof useLocation>
}

class ErrorBoundaryClass extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      hasError: false,
    }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    const { hasError } = this.state
    const { location } = this.props

    if (hasError && prevProps.location.pathname !== location.pathname) {
      this.setState({ hasError: false })
    }
  }

  render() {
    return this.state.hasError ? <ErrorPage /> : this.props.children
  }
}

const withRouter = <Props extends WithRouterProps>(Component: ComponentType<Props>) => {
  return (props: Omit<Props, keyof WithRouterProps>) => {
    const location = useLocation()

    return <Component {...(props as Props)} location={location} />
  }
}

export const ErrorBoundary = withRouter(ErrorBoundaryClass)
