import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { ScreenWrapper } from '../../components/ScreenWrapper'

export const Settings: FC = () => {
  const { t } = useTranslation()
  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('xxx')
  }, [])
  return <ScreenWrapper title={t('settings.title')}>@TODO</ScreenWrapper>
}
