const removeOidcQueryParams = (url: string) => {
  return url
    .replace(/[?&](code|scope|state|session_state)=[^&$]*/g, (match, p1) => (p1 === '?' ? '?' : ''))
    .replace(/\?&/, '?')
    .replace(/\?$/, '')
}

export const oidcConfig = {
  authority: process.env.REACT_APP_KEYCLOAK_AUTHORITY,
  client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
  redirect_uri: removeOidcQueryParams(location.href),
  post_logout_redirect_uri: location.origin,
  silent_redirect_uri: location.origin,
  revokeTokensOnSignout: true,
  onSigninCallback: () => {
    history.replaceState(null, window.name, removeOidcQueryParams(location.href))
  },
}
