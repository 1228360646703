import { ThemeOptions } from '@mui/material/styles'

export const themeOptions: ThemeOptions = {
  typography: {
    button: {
      textTransform: 'none',
    },
  },
  palette: {
    mode: 'light',
    background: {
      default: '#F5F5FA',
    },
    primary: {
      main: '#00004B',
    },
    secondary: {
      main: '#0073FF',
    },
    success: {
      main: '#1DBF6E',
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: '#00004B',
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          border: 'none',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          color: '#242C7F',
          fontWeight: '600',
          padding: '11px 24px',
          border: '1px solid rgba(36, 43, 126, 0.54)',

          '&.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
            border: '1px solid rgba(36, 43, 126, 0.54)',
          },

          '&.Mui-selected': {
            backgroundColor: 'rgba(36, 43, 126, 0.08)',
          },

          '&:not(.Mui-selected)': {
            backgroundColor: 'white',
          },
        },
      },
    },
  },
}

export const darkThemeOptions: ThemeOptions = {
  typography: {
    button: {
      textTransform: 'none',
    },
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#00004B',
    },
    secondary: {
      main: '#f50057',
    },
    success: {
      main: '#1DBF6E',
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: '#00004B',
        },
      },
    },
  },
}
