import { FC, ReactNode, useEffect, useState } from 'react'
import { initReactI18next } from 'react-i18next'
import _i18n, { InitOptions } from 'i18next'
import Backend from 'i18next-http-backend'

const getOptions = (language: string): InitOptions => ({
  fallbackLng: {
    default: ['cs'],
  },
  lng: language,
  supportedLngs: ['cs'],
  load: 'languageOnly',
  detection: {
    order: ['localStorage', 'navigator'],
    caches: ['localStorage'],
    lookupLocalStorage: 'i18nextLng',
  },
  nsSeparator: false,
  keySeparator: '.',
  backend: {
    loadPath: process.env.PUBLIC_URL + `/locales/{{lng}}.json?${new Date().getTime()}`,
  },
})

type Props = {
  language: string
  children: ReactNode
}

export const I18nProvider: FC<Props> = ({ language, children }) => {
  const [i18nLoaded, setI18nLoaded] = useState(false)

  useEffect(() => {
    _i18n
      .use(Backend)
      .use(initReactI18next)
      .init(getOptions(language), () => {
        setI18nLoaded(true)
      })
  }, [language])

  return i18nLoaded ? <>{children}</> : null
}
