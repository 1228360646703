import { useTheme } from '@mui/material/styles'

export const useStyles = () => {
  const theme = useTheme()

  return {
    field: {
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
  }
}
