import { FC, ReactNode } from 'react'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'

type Props = {
  title: string
  children: ReactNode
  filter?: ReactNode
}

export const ScreenWrapper: FC<Props> = ({ title, children, filter }) => {
  return (
    <Container sx={{ padding: (theme) => theme.spacing(3, 1), minWidth: '100%' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography variant="h3" sx={{ fontWeight: 400 }}>
            {title}
          </Typography>
        </Box>
        <Box>{filter}</Box>
      </Box>
      {children}
    </Container>
  )
}
