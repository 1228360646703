import { useTheme } from '@mui/material/styles'

export const useStyles = () => {
  const theme = useTheme()

  return {
    header: {
      m: 1,
      ml: 4,
      mb: 12,
      color: theme.palette.primary.main,
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      gap: 4,
      alignItems: 'center',
    },
    title: {
      display: 'flex',
      gap: 4,
    },
    icon: {
      fontSize: '48px',
    },
    text: {
      textAlign: 'center',
      maxWidth: 700,
    },
  }
}
