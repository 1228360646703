import { FC, StrictMode } from 'react'
import { AuthProvider } from 'react-oidc-context'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import { oidcConfig } from '../../oidc'
import { persistor, store } from '../../store'
import { LocalizedThemedContainer } from '../LocalizedThemedContainer'

export const Root: FC = () => {
  return (
    <StrictMode>
      <AuthProvider {...oidcConfig}>
        <Provider store={store}>
          <PersistGate persistor={persistor} loading={null}>
            <LocalizedThemedContainer />
          </PersistGate>
        </Provider>
      </AuthProvider>
    </StrictMode>
  )
}
