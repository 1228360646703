import { Dispatch, FC } from 'react'
import { startOfDay, subMinutes } from 'date-fns'
import { DatePicker } from '@mui/x-date-pickers'

type Props = {
  value: Date
  router: Dispatch<Date>
  fullWidth?: boolean
}

export const DateFilter: FC<Props> = ({ value, router, fullWidth }) => {
  return (
    <DatePicker
      value={value}
      onChange={(value) => router(new Date(subMinutes(startOfDay(value!), value!.getTimezoneOffset()).toISOString()))}
      format="EEEE - dd.MM.yyyy"
      slotProps={{
        textField: {
          size: 'small',
          variant: 'filled',
          fullWidth,
          sx: {
            '& .MuiInputBase-input': {
              paddingTop: '9px',
              paddingBottom: '8px',
              textTransform: 'capitalize',
            },
          },
        },
      }}
      sx={{ width: fullWidth ? '100%' : 160 }}
    />
  )
}
