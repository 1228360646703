import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material'
import Box from '@mui/material/Box'

import { toast } from '../../components/LsToast'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { TableSkeleton } from '../../components/TableSkeleton'
import { ProductDto, WeeklySummaryOrderDto } from '../../generated/api/breadlove'
import { getWeeklySummaryOrders } from '../../service/orders.service'
import { getProducts } from '../../service/products.service'
import { useStyles } from './styles'

export const WeeklySummary: FC = () => {
  const styles = useStyles()
  const { t } = useTranslation()
  const [date, setDate] = useState<Date>(new Date())
  const [checkboxes, setCheckboxes] = useState<boolean[]>([true, true, true, true, true, true, true])
  const [productsLoading, setProductsLoading] = useState<boolean>(true)
  const [weeklySummaryLoading, setWeeklySummaryLoading] = useState<boolean>(true)
  const [products, setProducts] = useState<ProductDto[]>([])
  const [weeklySummary, setWeeklySummary] = useState<WeeklySummaryOrderDto[]>([])
  const [selectedBtn, setSelectedBtn] = useState<'last_week' | 'this_week' | 'next_week'>('this_week')

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    option: 'last_week' | 'this_week' | 'next_week' | null
  ) => {
    if (option !== null) {
      setSelectedBtn(option)
      const date = new Date()
      if (option === 'last_week') {
        date.setDate(date.getDate() - 7)
      } else if (option === 'next_week') {
        date.setDate(date.getDate() + 7)
      }
      setDate(date)
    }
  }

  const loadProducts = async () => {
    setProductsLoading(true)
    try {
      const res = await getProducts(new Date())
      setProducts(res.data)
    } catch {
      toast.error(t('general.notification.unexpectedError'))
    } finally {
      setProductsLoading(false)
    }
  }

  const loadWeeklySummary = async () => {
    setWeeklySummaryLoading(true)
    try {
      const res = await getWeeklySummaryOrders(date)
      setWeeklySummary(res.data)
    } catch {
      toast.error(t('general.notification.unexpectedError'))
    } finally {
      setWeeklySummaryLoading(false)
    }
  }

  useEffect(() => {
    void loadProducts()
  }, [])

  useEffect(() => {
    void loadWeeklySummary()
  }, [date])

  return (
    <ScreenWrapper
      title={t('weeklySummary.title')}
      filter={
        <Box>
          <ToggleButtonGroup color="primary" value={selectedBtn} exclusive onChange={handleChange} aria-label="date">
            <ToggleButton value="last_week">{t('weeklySummary.filters.lastWeek')}</ToggleButton>
            <ToggleButton value="this_week"> {t('weeklySummary.filters.thisWeek')}</ToggleButton>
            <ToggleButton value="next_week">{t('weeklySummary.filters.nextWeek')}</ToggleButton>
          </ToggleButtonGroup>
        </Box>
      }
    >
      <Box mt={2}>
        {!productsLoading && !weeklySummaryLoading ? (
          <TableContainer component={Paper} sx={{ marginBottom: '80px' }}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={styles.header}>
                    <Checkbox
                      checked={checkboxes.every((val) => val === true)}
                      indeterminate={checkboxes.some((val) => val !== checkboxes[0])}
                      onChange={(event, checked) => {
                        const newCheckboxes = [...checkboxes]
                        newCheckboxes.fill(checkboxes.some((val) => val !== checkboxes[0]) ? false : checked)
                        setCheckboxes(newCheckboxes)
                      }}
                    />
                  </TableCell>
                  <TableCell sx={styles.header}>{t('weeklySummary.table.day')}</TableCell>
                  {products.map((product) => (
                    <TableCell key={`h${product.id}`} sx={styles.header} align="right">
                      {product.name}
                    </TableCell>
                  ))}
                  <TableCell sx={styles.header} align="right">
                    {t('weeklySummary.table.total')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {[1, 2, 3, 4, 5, 6, 0].map((day) => (
                  <TableRow key={day}>
                    <TableCell component="th" scope="row" sx={styles.header}>
                      <Checkbox
                        checked={checkboxes[day]}
                        onChange={() => {
                          const newCheckboxes = [...checkboxes]
                          newCheckboxes[day] = !newCheckboxes[day]
                          setCheckboxes(newCheckboxes)
                        }}
                      />
                    </TableCell>
                    <TableCell component="th" sx={styles.header}>
                      {t(`weeklySummary.days.${day}`)}
                    </TableCell>
                    {products.map((product) => (
                      <TableCell key={`b${product.id}`} sx={styles.cell} align="right">
                        {weeklySummary.find((summary) => summary.dayOfWeek === day && summary.productId === product.id)
                          ?.count || 0}
                      </TableCell>
                    ))}
                    <TableCell sx={styles.header} align="right">
                      {weeklySummary
                        .filter((summary) => summary.dayOfWeek === day)
                        .reduce((sum, summary) => sum + (summary.count || 0), 0)}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow key="sum" sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row"></TableCell>
                  <TableCell sx={styles.header}>{t('weeklySummary.table.total')}</TableCell>
                  {products.map((product) => (
                    <TableCell key={`s${product.id}`} sx={styles.cell} align="right">
                      {weeklySummary
                        .filter(
                          (summary) =>
                            checkboxes.every((val) => val === checkboxes[0]) || !!checkboxes[summary.dayOfWeek]
                        )
                        .filter((summary) => summary.productId === product.id)
                        .reduce((sum, summary) => sum + (summary.count || 0), 0)}
                    </TableCell>
                  ))}
                  <TableCell sx={styles.header} align="right">
                    {weeklySummary
                      .filter(
                        (summary) => checkboxes.every((val) => val === checkboxes[0]) || !!checkboxes[summary.dayOfWeek]
                      )
                      .reduce((sum, summary) => sum + (summary.count || 0), 0)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <TableSkeleton />
        )}
      </Box>
    </ScreenWrapper>
  )
}
