import React, { FC, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { InferType } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import { Grid, MenuItem, SwipeableDrawer, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'

import { DatePickerController } from '../../../components/forms/fields/DatePickerController'
import { NumInputController } from '../../../components/forms/fields/NumInputController'
import { SelectController } from '../../../components/forms/fields/SelectController'
import { TextFieldController } from '../../../components/forms/fields/TextFieldController'
import { OrderDto, PartnerDto, PickupPointDto, ProductDto } from '../../../generated/api/breadlove'
import { useSchema } from './schema'
import { useStyles } from './styles'

export type OrderFormValues = InferType<ReturnType<typeof useSchema>>

type Props = {
  order: OrderDto | null
  open: boolean
  products: ProductDto[]
  pickupPoints: PickupPointDto[]
  partners: PartnerDto[]
  onOpen: () => void
  onClose: () => void
  onSave: (form: OrderFormValues) => Promise<void>
}

export const SidePanel: FC<Props> = ({ order, products, pickupPoints, partners, open, onOpen, onClose, onSave }) => {
  const [disabled, setDisabled] = useState<boolean>(true)

  const { t } = useTranslation()
  const styles = useStyles()
  const schema = useSchema()

  const { handleSubmit, control, reset, getValues, setValue, watch } = useForm<OrderFormValues>({
    resolver: yupResolver(schema),
  })

  const type = watch('type')

  const productsForm = products.map((value) => ({ productId: value.id, count: 0 }))
  if (order?.products && order?.products.length > 0) {
    order.products.forEach((order) => {
      productsForm.find((value) => value.productId === order.productId)!.count = order.count
    })
  }
  productsForm.sort((a, b) => a.productId - b.productId)

  useEffect(() => {
    reset({
      type: order?.type ?? 'b2c',
      name: order?.name ?? '',
      phoneNumber: order?.phoneNumber ?? '',
      email: order?.email ?? '',
      note: order?.note ?? '',
      pickupPointId: order?.pickupPointId ?? pickupPoints[0]?.id ?? '',
      pickupDate: order?.pickupDate ? new Date(order.pickupDate) : undefined,
      products: productsForm,
    })
    setDisabled(!!order && !order.open)
  }, [order, open])

  return open ? (
    <SwipeableDrawer anchor="right" open={open} onClose={onClose} onOpen={onOpen}>
      <Box sx={{ width: 500, color: (theme) => theme.palette.primary.main }} role="presentation">
        <Box display="flex" justifyContent="right">
          <IconButton
            aria-label="close"
            title={t('close')}
            onClick={onClose}
            sx={{
              color: (theme) => theme.palette.common.black[100],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ padding: 2 }}>
          <Box mb={2}>
            <Typography variant="h4" sx={{ fontWeight: 400 }}>
              {t('orders.panel.title')}
            </Typography>
          </Box>
          <form onSubmit={handleSubmit(onSave)}>
            <Box>
              <Grid container direction="column">
                <Grid item>
                  <Controller
                    name="type"
                    control={control}
                    render={({ field }) => (
                      <ToggleButtonGroup
                        disabled={disabled || !!order}
                        value={field.value}
                        onChange={(e, value) => field.onChange(value)}
                        color="primary"
                        exclusive
                        fullWidth
                      >
                        <ToggleButton value="b2c">{t('orders.panel.toggles.b2c')}</ToggleButton>
                        <ToggleButton value="b2b">{t('orders.panel.toggles.b2b')}</ToggleButton>
                      </ToggleButtonGroup>
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container direction="column" mb={2}>
                <Grid item>
                  <Typography variant="subtitle2" sx={styles.field}>
                    {t('orders.panel.name')}
                  </Typography>
                </Grid>
                <Grid item>
                  {type === 'b2c' && <TextFieldController name="name" control={control} disabled={disabled} />}
                  {type === 'b2b' && (
                    <SelectController
                      name="name"
                      disabled={disabled}
                      control={control}
                      options={partners.map((partner) => (
                        <MenuItem key={partner.name} value={partner.name}>
                          {partner.name}
                        </MenuItem>
                      ))}
                    />
                  )}
                </Grid>
              </Grid>
              <Grid container direction="column" mb={2} sx={type === 'b2b' ? { display: 'none' } : {}}>
                <Grid item>
                  <Typography variant="subtitle2" sx={styles.field}>
                    {t('orders.panel.phoneNumber')}
                  </Typography>
                </Grid>
                <Grid item>
                  <TextFieldController name="phoneNumber" control={control} disabled={disabled} />
                </Grid>
              </Grid>
              <Grid container direction="column" mb={2} sx={type === 'b2b' ? { display: 'none' } : {}}>
                <Grid item>
                  <Typography variant="subtitle2" sx={styles.field}>
                    {t('orders.panel.email')}
                  </Typography>
                </Grid>
                <Grid item>
                  <TextFieldController name="email" control={control} disabled={disabled} />
                </Grid>
              </Grid>
              <Grid container direction="column" mb={2}>
                <Grid item>
                  <Typography variant="subtitle2" sx={styles.field}>
                    {t('orders.panel.pickupDate')}
                  </Typography>
                </Grid>
                <Grid item>
                  <DatePickerController name="pickupDate" control={control} disabled={disabled} />
                </Grid>
              </Grid>
              <Grid container direction="column" mb={4} sx={type === 'b2b' ? { display: 'none' } : {}}>
                <Grid item>
                  <Typography variant="subtitle2" sx={styles.field}>
                    {t('orders.panel.pickupPoint')}
                  </Typography>
                </Grid>
                <Grid item>
                  <SelectController
                    name="pickupPointId"
                    disabled={disabled}
                    control={control}
                    options={pickupPoints.map((point) => (
                      <MenuItem key={point.id} value={point.id}>
                        {point.name}
                      </MenuItem>
                    ))}
                  />
                </Grid>
              </Grid>
              <Box mb={1}>
                <Typography variant="h5" sx={{ fontWeight: 400 }}>
                  {t('orders.panel.products')}
                </Typography>
              </Box>

              {products
                .sort((a, b) => a.id - b.id)
                .map((product, index) => (
                  <Box key={product.id} display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Box>
                      <Typography variant="subtitle2" fontWeight="600">
                        {product.name}
                      </Typography>
                    </Box>
                    <Box>
                      <NumInputController
                        getValue={getValues}
                        setValue={setValue}
                        name={`products.${index}.count` as keyof OrderFormValues}
                        control={control}
                        max={product.limit}
                        disabled={disabled}
                      />
                    </Box>
                  </Box>
                ))}

              <Grid container direction="column" mt={3} mb={2}>
                <Grid item>
                  <Typography variant="subtitle2" sx={styles.field}>
                    {t('orders.panel.note')}
                  </Typography>
                </Grid>
                <Grid item>
                  <TextFieldController
                    name="note"
                    multiline
                    disabled={disabled}
                    sx={{
                      '& .MuiInputBase-input': {
                        minHeight: 120,
                        paddingTop: '0px',
                        paddingBottom: '0px',
                      },
                    }}
                    control={control}
                  />
                </Grid>
              </Grid>
              <Box display="flex" justifyContent="end" alignItems="center" columnGap={1}>
                <Button variant="outlined" size="medium" onClick={onClose}>
                  {t('general.button.cancel')}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  size="medium"
                  startIcon={<AddIcon />}
                  disabled={disabled}
                  sx={{
                    background: (theme) => theme.palette.secondary.main,
                    color: (theme) => theme.palette.secondary.contrastText,
                  }}
                >
                  {t('general.button.save')}
                </Button>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
    </SwipeableDrawer>
  ) : (
    <></>
  )
}
