import React from 'react'
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form'
import { UseFormGetValues } from 'react-hook-form/dist/types/form'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { Button } from '@mui/material'
import Box from '@mui/material/Box'
import TextField, { TextFieldVariants } from '@mui/material/TextField'

type Props<Values extends FieldValues> = {
  name: FieldPath<Values>
  control: Control<Values>
  label?: string
  multiline?: boolean
  variant?: TextFieldVariants
  helperText?: string
  getValue: UseFormGetValues<Values>
  setValue: any
  min?: number
  max?: number
  disabled?: boolean
}

export const NumInputController = <Values extends FieldValues>({
  name,
  control,
  variant = 'filled',
  helperText,
  multiline,
  getValue,
  setValue,
  min = 0,
  max = 100,
  disabled,
}: Props<Values>) => {
  const incNum = () => {
    let actualState: number = getValue(name)
    if (actualState < max) {
      actualState++
      setValue(name, actualState, { shouldValidate: true })
    }
  }
  const decNum = () => {
    let actualState: number = getValue(name)
    if (actualState > min) {
      actualState--
      setValue(name, actualState, { shouldValidate: true })
    }
  }
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { name, onChange, ref, ...field }, fieldState }) => (
        <Box display="flex" justifyContent="space-between" alignItems="center" gap={1}>
          <Button
            variant="outlined"
            aria-label="remove"
            size="medium"
            onClick={decNum}
            sx={{ minWidth: 40, padding: '6px' }}
            disabled={disabled}
          >
            <RemoveIcon />
          </Button>
          <TextField
            inputRef={ref}
            {...field}
            name={name}
            variant={variant}
            hiddenLabel
            onChange={onChange}
            multiline={multiline}
            fullWidth
            size="small"
            error={!!fieldState.error}
            helperText={fieldState.error?.message ?? helperText ?? undefined}
            sx={{ width: 52 }}
            disabled={disabled}
          />
          <Button
            variant="contained"
            aria-label="add"
            size="medium"
            onClick={incNum}
            sx={{
              background: (theme) => theme.palette.secondary.main,
              color: (theme) => theme.palette.secondary.contrastText,
              minWidth: 40,
              padding: '6px',
            }}
            disabled={disabled}
          >
            <AddIcon />
          </Button>
        </Box>
      )}
    />
  )
}
