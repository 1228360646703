import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import CloseIcon from '@mui/icons-material/Close'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

import { OrderDto } from '../../../generated/api/breadlove'

type Props = {
  order: OrderDto | null
  open: boolean
  onClose: () => void
  onDelete: () => Promise<void>
}

export const DeleteModal: FC<Props> = ({ order, open, onClose, onDelete }) => {
  const { t } = useTranslation()

  return order ? (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ textTransform: 'none' }}>
        {t('orders.delete.title')}
        <IconButton
          aria-label="close"
          title={t('close')}
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ marginBottom: 2, marginTop: 2 }}>
        <Typography>{t('orders.delete.question', { order: order.name })}</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" size="medium" onClick={onClose}>
          {t('general.button.cancel')}
        </Button>
        <Button color="error" variant="contained" size="medium" onClick={onDelete}>
          {t('general.button.delete')}
        </Button>
      </DialogActions>
    </Dialog>
  ) : null
}
