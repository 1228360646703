import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import NewReleasesIcon from '@mui/icons-material/NewReleases'
import { Button, Grid, Typography } from '@mui/material'

import { Path } from '../../router/BaseRouter/enums'
import { ScreenWrapper } from '../ScreenWrapper'

export const ErrorPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <ScreenWrapper title={''}>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <NewReleasesIcon sx={{ fontSize: '50px' }} />
            <Typography variant="h3" sx={{ ml: 2 }}>
              {t('errorPage.500')}
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{ mt: 2 }}>
          <Grid container justifyContent="center">
            <Typography variant="h3">{t('errorPage.title')}</Typography>
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{ mt: 3 }}>
          <Grid container justifyContent="center">
            <Button color="primary" variant="contained" onClick={() => navigate(Path.HOME)}>
              {t('general.button.backToHomepage')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </ScreenWrapper>
  )
}
