import React, { FC } from 'react'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'

import { ProductSummary } from '../../../components/ProductSummary'
import { ProductDto } from '../../../generated/api/breadlove'

type Props = {
  products: ProductDto[]
  loading: boolean
}

export const OrdersSummary: FC<Props> = ({ products, loading }) => {
  return !loading ? (
    <Stack direction="row" spacing={3} sx={{ overflowY: 'scroll', paddingBottom: 2 }}>
      {products
        .filter((product) => product.count > 0)
        .map((product) => (
          <ProductSummary key={product.id} name={product.name} total={product.count} used={product.closed} />
        ))}
    </Stack>
  ) : (
    <Stack direction="row" spacing={3} sx={{ paddingBottom: 2 }}>
      <Skeleton variant="rounded" width={232} height={154} />
      <Skeleton variant="rounded" width={232} height={154} />
      <Skeleton variant="rounded" width={232} height={154} />
    </Stack>
  )
}
