import React, { FC } from 'react'
import { Stack } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'

export const TableSkeleton: FC = () => {
  return (
    <Stack spacing={0.1}>
      <Skeleton variant="rounded" width={'100%'} height={56} />
      <Skeleton variant="rounded" width={'100%'} height={72} />
      <Skeleton variant="rounded" width={'100%'} height={72} />
      <Skeleton variant="rounded" width={'100%'} height={72} />
    </Stack>
  )
}
