import { FC } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { NotFound } from '../../views/NotFound'
import { Orders } from '../../views/Orders'
import { PartnersSummary } from '../../views/PartnersSummary'
import { Settings } from '../../views/Settings'
import { WeeklySummary } from '../../views/WeeklySummary'
import { Path } from './enums'

export const BaseRouter: FC = () => {
  return (
    <Routes>
      <Route path={Path.HOME} element={<Navigate to={Path.ORDERS} replace />} />
      <Route path={Path.ORDERS} element={<Orders />} />
      <Route path={Path.SETTINGS} element={<Settings />} />
      <Route path={Path.WEEKLY_SUMMARY} element={<WeeklySummary />} />
      <Route path={Path.PARTNERS_SUMMARY} element={<PartnersSummary />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
