export const useJwt = () => {
  const getDecodedPayload = (jwt: string) => {
    const base64url = jwt.split('.')[1]
    let base64 = base64url.replace(/-/g, '+').replace(/_/g, '/')
    const padding = base64.length % 4

    if (padding) {
      base64 += '='.repeat(4 - padding)
    }

    return JSON.parse(window.atob(base64))
  }

  return { getDecodedPayload }
}
