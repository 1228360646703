import { alpha } from '@mui/material'
import { useTheme } from '@mui/material/styles'

export const useStyles = () => {
  const theme = useTheme()

  return {
    header: {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
    cellOpen: {
      color: theme.palette.primary.main,
      height: '64px',
    },
    cellOpen60: {
      color: alpha(theme.palette.primary.main, 0.6),
      height: '64px',
    },
    cellClose: {
      color: theme.palette.primary.main,
      height: '64px',
      backgroundColor: theme.palette.background.default,
      textDecoration: 'line-through',
    },
    cellClose60: {
      color: alpha(theme.palette.primary.main, 0.6),
      height: '64px',
      backgroundColor: theme.palette.background.default,
      textDecoration: 'line-through',
    },
    icon: {
      color: alpha(theme.palette.primary.main, 0.6),
    },
    openIcon: {
      color: alpha(theme.palette.success.main, 0.6),
    },
    closeIcon: {
      color: alpha(theme.palette.error.main, 0.6),
    },
    chipOpen: {
      marginRight: '8px',
    },
    chipClose: {
      marginRight: '8px',
      textDecoration: 'line-through',
    },
  }
}
