import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'

type Props = {
  name: string
  total: number
  used: number
}
export const ProductSummary: FC<Props> = ({ name, total, used }) => {
  const { t } = useTranslation()
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'left',
        borderRadius: 1,
        padding: (theme) => theme.spacing(2),
        backgroundColor: (theme) => theme.palette.common.white,
        boxShadow: 2,
        minWidth: 232,
        maxWidth: 232,
        height: 154,
      }}
    >
      <Box>
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 600,
          }}
        >
          {name}
        </Typography>
      </Box>
      <Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
            {t('orders.productSummary.remains')}: {total - used}
          </Typography>
          <Typography variant="subtitle2">
            {used}/{total}
          </Typography>
        </Box>
        <LinearProgress variant="determinate" color="success" value={(used / total) * 100} />
      </Box>
    </Box>
  )
}
