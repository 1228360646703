export const getNextDays = (currentDate = new Date(), daysToAdd = 1): Date => {
  const nextDate = new Date(currentDate)
  nextDate.setDate(currentDate.getDate() + daysToAdd)
  return nextDate
}

export const getBeforeDays = (currentDate = new Date(), daysToDec = 1): Date => {
  const nextDate = new Date(currentDate)
  nextDate.setDate(currentDate.getDate() - daysToDec)
  return nextDate
}

export const areSameDate = (date1: Date, date2: Date): boolean => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  )
}
