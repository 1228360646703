import { ReactElement } from 'react'
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import Select from '@mui/material/Select'
import { SxProps } from '@mui/material/styles'
import { TextFieldVariants } from '@mui/material/TextField'

type Props<Values extends FieldValues> = {
  name: FieldPath<Values>
  control: Control<Values>
  options: ReactElement[] | ReactElement
  variant?: TextFieldVariants
  sx?: SxProps
  disabled?: boolean
}

export const SelectController = <Values extends FieldValues>({
  name,
  control,
  options,
  variant = 'filled',
  disabled,
}: Props<Values>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <FormControl
          variant={variant}
          sx={{
            width: '100%',
            '& .MuiInputBase-input': {
              paddingTop: '9px',
              paddingBottom: '8px',
            },
          }}
        >
          <Select {...field} labelId={name} error={!!fieldState.error} disabled={disabled}>
            {options}
          </Select>
          {fieldState.error?.message && <FormHelperText error>{fieldState.error.message}</FormHelperText>}
        </FormControl>
      )}
    />
  )
}
