import React, { FC } from 'react'
import { alpha, Avatar, Chip } from '@mui/material'

type Props = {
  num: number
  label: string
}

export const NumberChip: FC<Props> = ({ num, label }) => {
  return (
    <Chip
      size="medium"
      avatar={
        <Avatar
          sx={{
            backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.54),
          }}
        >
          {num}
        </Avatar>
      }
      label={label}
      sx={{
        backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.08),
        color: (theme) => theme.palette.primary.main,
        '& .MuiChip-avatar': {
          color: (theme) => theme.palette.common.white,
        },
      }}
    />
  )
}
