import { useTheme } from '@mui/material/styles'

export const useStyles = () => {
  const theme = useTheme()

  return {
    header: {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
    cell: {
      color: theme.palette.primary.main,
      height: '64px',
    },
  }
}
